import React from "react";
import {
  SubLayout,
  Clinics,
  CareerParticle,
  ContactParticle,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const ClinicsPage = () => {
  return (
    <SubLayout>
      <Seo
        title="施設一覧"
        description="東京都を中心に関東一円に安心して受けられる医療サービスを展開していきます。これからは関東だけでなく全国でグレースグループの医療サービスを享受できるインフラを整えていきます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,施設紹介,クリニック一覧,CLINIC"
      />
      <Clinics />
      <CareerParticle />
      <ContactParticle />
    </SubLayout>
  );
};

export default ClinicsPage;
